import Image from 'next/image'

type LogoProps = {
  color?: 'white' | 'black';
  width?: string;
  height?: string;
};

export function Logo({ color = 'white', width = 'auto', height = '32' }: LogoProps) {
  return (
    <div className="relative w-full h-16">
      <Image src="/document/logo-trevor.jpg" alt="" fill className="object-contain object-left" />
    </div>
  );
}
